<template>
  <div>
    <div class="form-title">{{editType | typeName}}组合信息</div>
    <el-form size="small" inline ref="form" :model="form" label-width="auto"  >
    <el-collapse v-model="activeNames">
      <el-collapse-item title="基础信息" name="1">
        <div style="text-align:center;">
        <div style="margin:auto;">
          <el-form-item label="名称" prop="name" :rules="getRules('名称不可为空')">
            <el-input v-model="form.name" placeholder="请输入名称" style="width:300px" :disabled="disabled"></el-input>
          </el-form-item>          
          
          <el-form-item label="编码" prop="code" :rules="[{ required: true, message: '请输入编码', trigger: 'blur' }]" >
              <el-input v-model="form.code" maxlength="50" placeholder="请输入编码" :disabled="disabled" style="width:300px"></el-input>
          </el-form-item>
        </div>
        <div style="margin:auto;">
          <el-form-item label="原价" prop="unitPrice" :rules="[{ required: true, message: '原价不能为空', trigger: 'blur' }]" >
              <el-input v-model="form.unitPrice" type="number" placeholder="请输入原价" :disabled="disabled" style="width:300px"></el-input>
          </el-form-item>
          <el-form-item label="优惠价" prop="unitPrice" :rules="[{ required: true, message: '优惠价不能为空', trigger: 'blur' }]" >
              <el-input v-model="form.favorablePrice" type="number" placeholder="请输入优惠价" :disabled="disabled" style="width:300px"></el-input>
          </el-form-item>
        </div>
        <div style="margin:auto;">
          <el-form-item label="显示口味" prop="unitPrice" :rules="[{ required: true, message: '请选择是否显示口味', trigger: 'blur' }]" >
            <el-select v-model="form.isShowFlavor" placeholder="请选择" style="width:300px"  :disabled="disabled">
              <el-option label="显示" value="1"></el-option>
              <el-option label="不显示" value="0"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="备注" prop="code"  >
              <el-input v-model="form.remark" type="textarea" maxlength="200" placeholder="请输入备注" :disabled="disabled" style="width:300px"></el-input>
          </el-form-item>
        </div>

        <div style="width:790px;text-align:left;margin:auto;">          
          <el-form-item label="图片" prop="thumbnailList" :rules="[{ required: true, message: '请选择图片', trigger: 'blur' }]" >
              <UploadImg v-model="form.thumbnailList" :limit="1" :disabled="disabled"></UploadImg>
          </el-form-item>
        </div>

        </div>
      </el-collapse-item>

      <el-collapse-item title="商品信息" name="1">

        <div style="text-align:center;">
          <el-button type="primary" icon="el-icon-search" v-if="editType!='show'" @click="showSelectGoods(true)">选择商品</el-button>
        </div>
        <div style="width:780px; text-align:left;margin:auto;">
          <div class="goodsBox" v-for="(goods,index) in form.goodsList" :key="goods.id">
            <div class="goods-img" title="点击查看大图">
              <el-image :src="getFirstFileByList(goods.thumbnailList).url" class="image"  @click.stop="showBigImage(getFirstFileByList(goods.thumbnailList).url)"></el-image>
             </div>
            <div class="info-box">
              <div class="info-box-top">
                <div class="info-item">名称：{{goods.name}}</div>
                <div class="info-item">单价：{{goods.unitPrice}}</div>
                <div class="info-item">分类：{{goods.goodsTypeName}}</div>
                <div class="info-item">品牌：{{goods.brandName}}</div>
              </div>
              <div class="info-box-bottom">
                <div class="info-item">销售单位：{{goods.saleMeasureUnitName}}{{goods.saleMeasureUnitType==1?'（单计量）':'（多剂量）'}}</div>
                <div class="info-item">规格型号：{{goods.model}}</div>
                <div class="info-item-favorable">优惠价格：
                  <el-select v-model="goods.favorablePrice" placeholder="请选择" style="width:200px"  :disabled="disabled" @change="handlePackagePrice">
                    <el-option   :value="goods.unitPrice" label="无优惠"  ></el-option>
                  <el-option  v-for="(priceInfo,index) in goods.favorablePriceList" :key="index" :value="priceInfo.price" :label="priceInfo.price+'元('+priceInfo.name+')'"  ></el-option>
                  </el-select>
                </div>
                
              </div>

            </div>
            <div class="del-box">
              <el-button type="danger" v-if="editType!='show'" icon="el-icon-delete" circle @click="removeGoods(index)"></el-button>
            </div>
          </div>
        </div>
      </el-collapse-item>
    </el-collapse>
    <div class="btn-box">
      <el-button @click="cancel">返回</el-button>
      <el-button type="primary" v-debounce="submit" v-if="editType!='show'" >保存</el-button>
    </div>

    </el-form>

    <SelectGoods v-if="isShowSelectGoodsModal" v-model="isShowSelectGoodsModal" :isShowFavorablePrice="true" @okSelectedGoods="okSelectedGoods" ></SelectGoods>

    <el-image-viewer v-if="imgDialogVisible" :on-close="onClose" :url-list="[bigImageUrl]" />


  </div>
</template>

<script>
import { addCombinationInfo, updateCombinationInfo,getCombinationInfo } from '@/api/index.js'
import UploadImg from '@/components/UploadImg/index.vue'
import SelectGoods from '@/components/MultipleChooseGoods/index.vue'
import ElImageViewer from 'element-ui/packages/image/src/image-viewer'

export default {
  name: 'CustomerForm',
  components: { UploadImg,SelectGoods,ElImageViewer },
  props: ['value', 'editId', 'editType'],
  data() {
    return {
      activeNames: ['1', '2'],
      form:{
        id:'',
        name:'',
        remark:'',
        code:'',
        unitPrice:'',
        favorablePrice:'',
        isShowFlavor:'',
        goodsList:[],
        thumbnailList:[]
      },
      disabled:false,
      isShowSelectGoodsModal: false,
      imgDialogVisible:false,
      bigImageUrl:''
    }
  },
  created() {
    this.disabled = this.editType=='show';
    if (this.editType !='add' ) {
      this.form.id = this.editId;
      this.getPackageInfo();
    }
  },
  mounted() {
    
  },
  methods: {
    getRules(message){
      if(this.disabled){
        return [];
      }
      return [{ required: true, message: message, trigger: 'blur' }];
    },
    cancel() {
      this.$emit('input', 0)
      this.$emit('changeShowType', 'list')
    },
    showBigImage(url){
      this.imgDialogVisible = true;
      this.bigImageUrl = url;
    },
    onClose() {
      this.imgDialogVisible = false
    },
    removeGoods(index){
      this.form.goodsList.splice(index,1)
      this.handlePackagePrice();
    },
    okSelectedGoods(goodsList){      
      //以下为去重处理
      let allList = this.form.goodsList.concat(goodsList);

      let typeSet = new Set();
      for(let item of allList){
        typeSet.add(item.saleMeasureUnitType);
      }
      if(typeSet.size>1){
        this.$message.error('所选商品销售单位只能为一种计量方式！');
        return;
      }
      let newList = [];
      for(let item1 of allList){
        let flag = true;
        for(let item2 of newList){
          if(item1.originalId == item2.originalId){
            flag=false;
          }
        }
        if(flag){
          newList.push(item1)
        }
      }

      this.form.goodsList=[...newList];
      this.isShowSelectGoodsModal = false;
      this.handlePackagePrice();
    },
    handlePackagePrice(){
      let price = 0;
      let favorablePrice = 0;
      for(let goods of this.form.goodsList){
        price+= parseFloat(goods.unitPrice);
        if(goods.favorablePrice){
          favorablePrice+= parseFloat(goods.favorablePrice);
        }else{
          favorablePrice+= parseFloat(goods.unitPrice);
        }

      }
      this.form.unitPrice = price.toFixed(2)
      this.form.favorablePrice=favorablePrice.toFixed(2);

    },
    showSelectGoods(type){
      this.isShowSelectGoodsModal = type;
    },
    goodsTypeChange(item){
      this.form.goodsTypeName = item.name
    },
    brandChange(item){
      this.form.brandName = item.name
    },
    saleMeasureUnitChange(item){
      this.form.saleMeasureUnitName = item.name;
    },
    stockMeasureUnitChange(item){
      this.form.stockMeasureUnitName = item.name;
    },
    reportMeasureUnitChange(item){
      this.form.reportMeasureUnitName = item.name;
    },
    //编辑获取详情
    getPackageInfo(){
      let param = {id:this.editId};      
      getCombinationInfo(param).then(res => {
        if (res.code != 200) return
        this.form = res.data;        
      })
    },
    getFirstFileByList(list){
      if(list && list.length>0){
        return list[0];
      }
      return {};
    },
    submit() {
      
     if (!this.$validate('form')) return
     if(this.form.goodsList.length==0){
        this.$message.error('请选择商品')
        return;
      }
      let form = JSON.parse(JSON.stringify(this.form));
      let success = res => {
        if (res.code != 200) {
          this.$message.error(res.msg || '操作失败')
          return
        }
        this.$message.success('操作成功')
        this.cancel()
      }
      if (this.editType == 'add') {
        addCombinationInfo(form).then(success)
      } else {
        updateCombinationInfo(form).then(success)
      }
    },
  },
}
</script>

<style scoped>
.goodsBox{
  width:870px; 
  display: flex; 
  border: 2px solid #C9E3FB;
  border-radius: 5px;
  margin-top:10px;
}
.goodsBox .goods-img{
    flex: 0 0 100px;
    height: 100px;
    width: 100px;
    line-height: 100px;
    cursor: pointer;
}
.goodsBox .goods-img .image{
    height: 80px;
    width: 80px;
    margin: 10px 10px;
}
.goodsBox .info-box{
  flex: 0 0 720px;

}
.goodsBox .info-box .info-box-top{
  height: 50px;
  display: flex;
}
.goodsBox .info-box .info-box-bottom{
  height: 50px;
  display: flex;
}
.goodsBox .info-box .info-item{
  flex: 0 0 180px;
  line-height: 50px;
  text-align: left;
  font-size: 14px;
  color: #606266;
}

.goodsBox .info-box .info-item-favorable{
  flex: 0 0 3600px;
  line-height: 50px;
  text-align: left;
  font-size: 14px;
  color: #606266;
}



.goodsBox .del-box{
  flex: 1;
  width: 50px;
  line-height: 100px;
}

</style>
