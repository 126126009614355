<template>
  <div style="width:100%">
    <CombinationList v-if="editType == 'list'"  @changeShowType="changeShowType"></CombinationList>
    <CombinationForm v-if="editType == 'edit' || editType == 'add'" :editId="editId"  :editType="editType"  @changeShowType="changeShowType"></CombinationForm>
    <CombinationInfo v-if="editType == 'show'" :editId="editId"  @changeShowType="changeShowType"></CombinationInfo>
  </div>
</template>

<script>
import CombinationList from './components/CombinationList'
import CombinationForm from './components/CombinationForm'
import CombinationInfo from './components/CombinationInfo'
export default {
  name: 'combination',
  components: { CombinationForm,CombinationList,CombinationInfo },
  data() {
    return {
      editType:'list',
      editId:'',
    }
    
  },
  created() {
  },
  methods: {
    changeShowType(type,id){
      if(type!='add'){
        this.editId = id;
      }
      this.editType = type;
    }
  }
}
</script>

<style>
</style>