<template>
   <el-cascader ref="changeGoods" :options="list" v-model="value2" @change="change" :disabled="disabled"
   :props="{checkStrictly:checkStrictly,value:'id',label:'name',children:'childrenList' }" clearable ></el-cascader>
</template>

<script>
import { getGoodsTypeTreeList } from '@/api/index'
export default {
  name: 'ChooseGoodsType',
  props: {
    value: Number | String,
    showAll: {
      type: Boolean,
      default: false,
    },
    checkStrictly: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    multiple: {
      type: Boolean,
      default: false,
    },

  },
  data() {
    return {
      list: [],
      value2: ''
    }
  },
  created() {
    getGoodsTypeTreeList({ isDisable: 0 }).then(res => {
      this.removeBlankChild(res.data)
      this.list = res.data
      this.onload()
    })
  },
  watch: {
    value(val) {
      if (val && !this.value2) {
        this.onload()
      }
    }
  },
  methods: {
    removeBlankChild(list){
      for(let item of list){
        if(item.childrenList.length>0){
          this.removeBlankChild(item.childrenList)
        }else{
          delete item.childrenList;
        }
      }

    },
    onload() {
      if (this.multiple && this.value) {
        this.value2 = this.value.split(',')
        this.value2 = this.value2.map(item => Number(item))
      } else {
        this.value2 = this.value
      }
    },
    change() {
       
        let selectItem = this.$refs['changeGoods'].getCheckedNodes();
        let item = {id:'',name:''}
        if(selectItem.length>0){
          item.id = selectItem[0].value;
          item.name = selectItem[0].label;
        }
        this.$emit('input', item.id)
        this.$emit('change', item)
    }
  }
}
</script>

<style>
</style>