<template>

<div style="width:99%;margin:auto;">
    <div class="form-title">组合详情</div>
    <div class="approve">
      <div class="basic-info-container">
        <h3 class="basic-info">基础信息</h3>
        <div class="basic-info-content">
          <div style="display:flex;flex-wrap:wrap">
            <div class="grid-content1">名称：<span class="col-text">{{form.name}}</span></div>
            <div class="grid-content1">编码：<span class="col-text ">{{form.code}}</span></div>
            <div class="grid-content1">原价：<span class="col-text red">￥{{form.unitPrice}}</span></div>
            <div class="grid-content1">优惠价：<span class="col-text red" >￥{{form.favorablePrice}}</span></div>
            <div class="grid-content1">显示口味：<span class="col-text">{{form.isShowFlavor=='1'?'显示':'不显示'}}</span></div>
            <div class="grid-content1">备注：<span class="col-text">{{form.remark}}</span></div>
            <div class="grid-content1">图片：<UploadImg v-model="form.thumbnailList" :limit="1" :disabled="true"></UploadImg></div>
          </div>
        </div>
      </div>
      <div class="basic-info-container">
        <h3 class="basic-info">商品信息</h3>
        <div class="product-header">
          <el-row :gutter="10" class="rol-content">
            <el-col :span="3">
              <div class="grid-content">名称</div>
            </el-col>
            <el-col :span="2">
              <div class="grid-content">单价</div>
            </el-col>
            <el-col :span="3">
              <div class="grid-content">分类</div>
            </el-col>
            <el-col :span="2">
              <div class="grid-content">品牌</div>
            </el-col>
            <el-col :span="3">
              <div class="grid-content">销售单位</div>
            </el-col>
            <el-col :span="3">
              <div class="grid-content">规格型号</div>
            </el-col>
            <el-col :span="3">
              <div class="grid-content">优惠价格</div>
            </el-col>
            <el-col :span="3">
              <div class="grid-content">图片</div>
            </el-col>
          </el-row>
        </div>
        <div class="product-content" v-for="(goods,index) in form.goodsList" :key="index">
          <el-row :gutter="10" class="rol-content">
            <el-col :span="3">
              <div class="grid-content">{{goods.name}}</div>
            </el-col>
            <el-col :span="2">
              <div class="grid-content">{{goods.unitPrice}}</div>
            </el-col>
            <el-col :span="3">
              <div class="grid-content">{{goods.goodsTypeName}}</div>
            </el-col>
            <el-col :span="2">
              <div class="grid-content">{{goods.brandName}}</div>
            </el-col>
            <el-col :span="3">
              <div class="grid-content">{{goods.saleMeasureUnitName}}{{goods.saleMeasureUnitType==1?'（单计量）':'（多剂量）'}}</div>
            </el-col>
            <el-col :span="3">
              <div class="grid-content">{{goods.model}}</div>
            </el-col>
            <el-col :span="3">
              <div class="grid-content">{{goods.favorablePrice}}</div>
            </el-col>
            <el-col :span="3" style="height:50px;">
              <div class="grid-content">
                <UploadImg  v-model="goods.thumbnailList" :limit="1" :disabled="true"></UploadImg>
              </div>
            </el-col>
          </el-row>
          <el-divider class="divder-product"></el-divider>
        </div>
        
      </div>
  

    </div>
    <div class="btn-box">
      <el-button @click="cancel">返回</el-button>
    </div>
  </div>
</template>

<script>
import { getCombinationInfo } from '@/api/index.js'
import UploadImg from '@/components/UploadImg/index.vue'
import ElImageViewer from 'element-ui/packages/image/src/image-viewer'

export default {
  name: 'CustomerForm',
  components: { UploadImg,ElImageViewer },
  props: ['value', 'editId', 'editType'],
  data() {
    return {
      form:{
        id:'',
        name:'',
        remark:'',
        code:'',
        unitPrice:'',
        favorablePrice:'',
        isShowFlavor:'',
        goodsList:[],
        thumbnailList:[]
      },
      disabled:false,
    }
  },
  created() {
    this.form.id = this.editId;
      this.getCombinationInfo();
  },
  mounted() {
    
  },
  methods: {
    cancel() {
      this.$emit('input', 0)
      this.$emit('changeShowType', 'list')
    },
    onClose() {
      this.imgDialogVisible = false
    },
    //编辑获取详情
    getCombinationInfo(){
      let param = {id:this.editId};      
      getCombinationInfo(param).then(res => {
        if (res.code != 200) return
        this.form = res.data;        
      })
    },
 
  },
}
</script>

<style lang="scss" scoped>
@import '@/assets/css/info.scss'
</style>
<style scoped>
.goodsBox{
  width:870px; 
  display: flex; 
  border: 2px solid #C9E3FB;
  border-radius: 5px;
  margin-top:10px;
}
.goodsBox .goods-img{
    flex: 0 0 100px;
    height: 100px;
    width: 100px;
    line-height: 100px;
    cursor: pointer;
}
.goodsBox .goods-img .image{
    height: 80px;
    width: 80px;
    margin: 10px 10px;
}
.goodsBox .info-box{
  flex: 0 0 720px;

}
.goodsBox .info-box .info-box-top{
  height: 50px;
  display: flex;
}
.goodsBox .info-box .info-box-bottom{
  height: 50px;
  display: flex;
}
.goodsBox .info-box .info-item{
  flex: 0 0 180px;
  line-height: 50px;
  text-align: left;
  font-size: 14px;
  color: #606266;
}

.goodsBox .info-box .info-item-favorable{
  flex: 0 0 3600px;
  line-height: 50px;
  text-align: left;
  font-size: 14px;
  color: #606266;
}



.goodsBox .del-box{
  flex: 1;
  width: 50px;
  line-height: 100px;
}



</style>
