<template>
<el-dialog title="选择商品" :visible.sync="value" :close-on-click-modal="false" :before-close="dialogClose" width="1100px">
  <div style="width:100%">
    <div class="search-box">
      <el-form inline size="small">
        <el-form-item label="模糊搜索">
          <el-input v-model="form.search" placeholder="名称、编号" style="width:120px"></el-input>
        </el-form-item>
        <el-form-item label="分类">
           <ChooseGoodsType v-model="form.goodsTypeId" :checkStrictly="true"  style="width:150px"></ChooseGoodsType>
        </el-form-item>
        <el-form-item label="品牌" >
            <ChooseBrand v-model="form.brandId"  style="width:120px" :showAll="true" ></ChooseBrand>
          </el-form-item>
        <el-form-item label="创建时间">
            <el-date-picker v-model="addDate" style="width:220px;"
              type="daterange" align="right" unlink-panels
              range-separator="至" value-format="yyyy-MM-dd"
              start-placeholder="开始日期" end-placeholder="结束日期" >
            </el-date-picker>
        </el-form-item>

        <el-form-item label=" ">
          <el-button type="primary" icon="el-icon-search" @click="handleCurrentChange(1)">查询</el-button>
        </el-form-item>

      </el-form>
    </div>
    <el-table :data="list" @selection-change="handleSelectionChange"
    row-key="id"
     style="max-width: 100%;margin-top:15px;box-shadow: 0 1px 4px 0 rgba(0,0,0,0.1);">
      <el-table-column type="selection" width="55"></el-table-column>
      <el-table-column label="名称" prop="name" align="center" show-overflow-tooltip width="150"></el-table-column>
      <el-table-column label="单价（元）" align="center" show-overflow-tooltip prop="unitPrice"></el-table-column>  
      <el-table-column label="分类" align="center" show-overflow-tooltip prop="goodsTypeName"></el-table-column>    
      <el-table-column label="品牌" align="center" show-overflow-tooltip prop="brandName"></el-table-column>   

      <el-table-column label="规格型号" align="center" show-overflow-tooltip prop="model"></el-table-column>    
      <el-table-column label="销售单位" align="center" show-overflow-tooltip width="120">
         <template slot-scope="scope">
           <span>{{scope.row.saleMeasureUnitName}}{{scope.row.saleMeasureUnitType==1?'（单计量）':'（多剂量）'}}</span>
         </template>
        </el-table-column>    
      <el-table-column label="库存单位" align="center" show-overflow-tooltip prop="stockMeasureUnitName"></el-table-column>    
      <el-table-column label="报表单位" align="center" show-overflow-tooltip prop="reportMeasureUnitName"></el-table-column>    
      <el-table-column label="创建时间" align="center" show-overflow-tooltip prop="createTime" width="160"></el-table-column>  
       <!-- <el-table-column align="center" label="图片" width="160">
        <template slot-scope="scope">
          <UploadImg v-model="scope.row.fileList" :limit="1" :disabled="true"></UploadImg>        
        </template>
      </el-table-column> -->
     
    </el-table>
    <el-pagination layout="total, prev, pager, next,sizes" @size-change="handleSizeChange" @current-change="handleCurrentChange" background :page-size="form.pageSize" :current-page="form.pageNum" :total="total"></el-pagination>
    <el-row>
    <el-col :span="24" style="margin-top:50px;">
        <el-button @click="dialogClose">关闭</el-button>
        <el-button type="primary" @click="onSave">保存</el-button>
    </el-col>
    </el-row>
  </div>
</el-dialog>
</template>

<script>
import { getGoodsList } from '@/api/index.js'
import ChooseUnit from '@/components/ChooseUnit/index.vue'
import ChooseBrand from '@/components/ChooseBrand/index.vue'
import ChooseGoodsType from '@/components/ChooseGoodsType/index.vue'
import UploadImg from '@/components/UploadImg/index.vue'
export default {
  name: 'multipleChooseGoods',
  components: {ChooseUnit,  ChooseBrand, ChooseGoodsType,UploadImg},
  props: 
  {
    value: Number | String,
    isShowFavorablePrice: {
      type: Boolean,
      default: false
    },
  },
  data() {
    return {
      showType: 0,
      editType: '',
      isAddDialog:false,
      form: {
        pageNum: 1,          //页码
        pageSize: 10,          //每页条数
        search: '',          //搜索条件
        goodsTypeId:'',
        brandId:'',
        startTime:'',
        endTime:'',
        isShowFavorablePrice:false
      },
      list: [],
      total: 0,
      addDate:[],
      selectGoodList:[]
    }
    
  },
  created() {
    this.form.isShowFavorablePrice = this.isShowFavorablePrice;
    this.getList();
  },
  methods: {
    dialogClose() {
      this.$emit('input', false)
    },
    onSave(){
      if(this.selectGoodList.length == 0){
        this.$message.error('请至少选择一项商品！');
        return;
      }
      this.$emit('okSelectedGoods',this.selectGoodList)
      //this.dialogClose();
    },
    handleSelectionChange(val){
      this.selectGoodList = val
    },
    getList() {
      if(this.addDate.length>0){
        this.form.startTime=this.addDate[0];
        this.form.endTime=this.addDate[1];
      }else{
        this.form.startTime='';
        this.form.endTime='';
      }
      getGoodsList(this.form).then(res => {
        if (res.code != 200) return
        this.list = res.data.records
        for(let item of this.list){
          item.originalId = item.id;
        }
        this.total = res.data.total
      })
    },
    handleSizeChange(val) {
      this.form.pageSize = val
      this.handleCurrentChange(1)
    },
    handleCurrentChange(val) {
      this.form.pageNum = val
      this.getList()
    },
  }
}
</script>

<style>
</style>